import { cva } from "class-variance-authority";

const root = cva(
  "relative z-[200] bg-popover border border-stroke/10 shadow-md shadow-black/5 rounded-lg overflow-hidden p-2",
  {
    variants: {
      size: {
        xs: "text-xs",
        sm: "text-sm",
        md: "text-base",
      },
    },
  },
);

const group = cva("border-b border-stroke/10 last:border-0 p-1");

const item = cva(
  ["rounded-lg flex items-center p-2 space-x-2 text-sm text-content outline-none", "data-[active-item]:bg-muted"],
  {
    variants: {
      noHover: {
        true: "",
        false: "hover:bg-muted cursor-pointer",
      },
    },
    defaultVariants: {
      noHover: false,
    },
  },
);

const separator = cva("h-[1px] bg-stroke/10 my-2 -mx-2");

const subtitle = cva("text-content/50 text-xs");

export const menu = { root, separator, group, item, subtitle };
